import React, { useState, useMemo } from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import { getHomeVideo } from "../services/getHomeVideo";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { toast } from "react-toastify";

const Section7 = ({ settingData }) => {
  const [homeVideo, setHomeVideo] = useState([]);

  useMemo(async () => {
    try {
      const [videoRes] = await Promise.all([getHomeVideo()]);
      setHomeVideo(videoRes.document);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const homeVideoFilter = homeVideo.filter((data) => data.isActive === true);

  return (
    <>
      {homeVideoFilter.length > 0 && (
        <section className="py-8">
          <div className="container container-xxl">
            <h1 className="fs-30 mb-8 text-center">
              {settingData[0]?.video_title}
            </h1>
            {homeVideoFilter.length > 4 ? <Slider {...settings}>
              {homeVideo.map((data, index) => {
                return (
                  data.isActive && (
                    <div key={index}>
                      {
                        data.videoCode && (
                          <LazyLoadComponent>
                            <ReactPlayer
                              url={data.videoCode}
                              width="100%"
                              height={430}
                            />
                          </LazyLoadComponent>
                        )
                      }
                    </div>
                  )
                );
              })}
            </Slider> :
              <div className="d-flex justify-content-center">
                {homeVideo.map((data, index) => {
                  return (
                    data.isActive && (
                      <div key={index}>
                        {data.videoCode && (
                          <LazyLoadComponent>
                            <ReactPlayer
                              url={data.videoCode}
                              width="100%"
                              height={430}
                            />
                          </LazyLoadComponent>
                        )}
                      </div>
                    )
                  );
                })}
              </div>}
          </div>
        </section >
      )}
    </>
  );
};

export default Section7;
