import React, { useState, useMemo } from "react";
import { getHomeContent } from "../services/getHomeContent";
import { toast } from "react-toastify";

const Section4 = ({ settingData }) => {
  const [about, setAbout] = useState([]);

  useMemo(async () => {
    try {
      const [homeContentRes] = await Promise.all([getHomeContent()]);
      setAbout(homeContentRes.data);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }, []);

  const activeAbout = useMemo(() => about.filter((data) => data.isActive && data.showInHome), [about]);

  return (
    <>
      {activeAbout.length > 0 && (
        <section className="pb-8">
          <div className="container container-xxl">
            <h4 className="text-lg-center sub-title">story of</h4>
            <h1 className="text-lg-center mb-7 fs-30">
              {settingData[0]?.about_title}
            </h1>
            {activeAbout.map((data, id) => (
              <div key={id}>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
};

export default Section4;
