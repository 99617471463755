import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getBrandProduct } from "../services/getBrandProduct";
import { ImageLink } from "../constants";
import { Helmet } from "react-helmet";
import { getMenuPage } from "../services/getMenuPage";
import Loader from "../layout/Loader";
import { getColor } from "../services/getColor";
import { toast } from "react-toastify";

const BrandProduct = ({ contact }) => {
  const location = useLocation();
  const navigator = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [brandProduct, setBrandProduct] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [animate, setAnimate] = useState(false);
  const pathname = location.pathname;
  const pathname2 = pathname.split("/").slice(2).join("/");
  const [menuPageData, setMenuPageData] = useState(null);
  const id = menuPageData?.brand?._id;
  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [purity, setPurity] = useState([]);
  const [metal, setMetal] = useState([]);
  const [uniqueColors, setUniqueColors] = useState([]);
  const [uniqueMetals, setUniqueMetals] = useState([]);
  const [uniquePurity, setUniquePurity] = useState([]);

  const [accordionState, setAccordionState] = useState({
    accordian1: true,
    accordian2: true,
    accordian3: true,
    accordian4: true,
    accordian5: true,
    accordian6: true,
    accordian7: true,
  });

  const toggleAccordion = (section) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  useMemo(async () => {
    if (pathname2) {
      setIsLoading(true);
      const [menuPageResponse] = await Promise.all([
        getMenuPage(`/${pathname2}`),
      ]);
      setMenuPageData(menuPageResponse.document);
      setIsLoading(false);
    }
  }, [pathname2]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useMemo(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [
          productsResponse,
        ] = await Promise.all([
          getBrandProduct({
            id: id,
            gender: gender,
            color: selectedColors,
            metal: metal,
            purity: purity,
          }),
          getColor()
        ]);
        setBrandProduct(productsResponse.document);
      } catch (error) {
        toast.error(error.response.data.error);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, gender, selectedColors, purity, metal]);

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 100;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = useMemo(
    () =>
      Array.isArray(brandProduct)
        ? brandProduct?.slice(indexOfFirstProduct, indexOfLastProduct)
        : [],
    [brandProduct, indexOfFirstProduct, indexOfLastProduct]
  );

  const totalPages = Math.ceil(
    (Array.isArray(brandProduct) ? brandProduct?.length : 0) / productsPerPage
  );

  useEffect(() => {
    setAnimate(true);
  }, [currentProducts]);

  const handleGenderChange = (event) => {
    const { value, checked } = event.target;
    setGender((prevState) => {
      if (checked) {
        return [...prevState, value];
      } else {
        return prevState.filter((item) => item !== value);
      }
    });
  };

  const handleColorChange = (e, colorId) => {
    if (e.target.checked) {
      setSelectedColors([...selectedColors, colorId]);
    } else {
      setSelectedColors(selectedColors.filter(id => id !== colorId));
    }
  };

  const handlePurityChange = (e, purityId) => {
    if (e.target.checked) {
      setPurity([...purity, purityId]);
    } else {
      setPurity(purity.filter(id => id !== purityId));
    }
  };

  const handleMetalChange = (e, metalId) => {
    if (e.target.checked) {
      setMetal([...metal, metalId]);
    } else {
      setMetal(metal.filter(id => id !== metalId));
    }
  };
  const filterFemale = currentProducts?.filter((item) => item.gender === 'Female');
  const filterMale = currentProducts?.filter((item) => item.gender === 'Male');
  const filterUnisex = currentProducts?.filter((item) => item.gender === 'Unisex');

  useEffect(() => {
    const allColors = Array.isArray(brandProduct)
      ? brandProduct.flatMap((item) => item.color?.map((color) => ({ id: color?._id, name: color?.name })))
      : [];

    const uniqueColorMap = new Map();
    allColors.forEach((color) => {
      if (!uniqueColorMap.has(color?.id)) {
        uniqueColorMap.set(color?.id, color?.name);
      }
    });
    const uniqueColors = Array.from(uniqueColorMap, ([id, name]) => ({ id, name }));
    setUniqueColors(uniqueColors);
  }, [brandProduct]);

  useEffect(() => {
    const allMetals = Array.isArray(brandProduct)
      ? brandProduct.flatMap((item) => item.metal?.map((metal) => ({ id: metal?._id, name: metal?.name })))
      : [];

    const uniqueMetalMap = new Map();
    allMetals.forEach((metal) => {
      if (!uniqueMetalMap.has(metal?.id)) {
        uniqueMetalMap.set(metal?.id, metal?.name);
      }
    });
    const uniqueMetals = Array.from(uniqueMetalMap, ([id, name]) => ({ id, name }));
    setUniqueMetals(uniqueMetals);
  }, [brandProduct]);

  useEffect(() => {
    const allPurity = Array.isArray(brandProduct)
      ? brandProduct.flatMap((item) => item.gold_purity?.map((purity) => ({ id: purity?._id, name: purity?.name })))
      : [];

    const uniquePurityMap = new Map();
    allPurity.forEach((purity) => {
      if (!uniquePurityMap.has(purity?.id)) {
        uniquePurityMap.set(purity?.id, purity?.name);
      }
    });
    const uniquePurity = Array.from(uniquePurityMap, ([id, name]) => ({ id, name }));
    setUniquePurity(uniquePurity);
  }, [brandProduct]);

  if (isLoading) {
    return (
      <div className="pre-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {menuPageData?.brand?.name && (
        <Helmet>
          <title>{`${menuPageData?.brand?.metaTitle}`}</title>
        </Helmet>
      )}

      <main id="content">
        <section
          className="inner-banner"
          style={{
            backgroundImage: `url(${ImageLink(
              menuPageData?.brand?.background_image,
            )})`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <div className="inner-banner-text">
                  <h1>{menuPageData?.brand?.name}</h1>
                  <p>{menuPageData?.brand?.subTitle}</p>
                </div>
              </div>
              <div className="col-lg-9">
                <img
                  className="w-100 inner-banner-img"
                  src={ImageLink(menuPageData?.brand?.main_banner_image)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* {menuPageData?.brand?.background_image ? <section
          className="inner-banner"
          style={{
            backgroundImage: `url(${ImageLink(
              menuPageData?.brand?.background_image
            )})`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <div className="inner-banner-text">
                  <h1>{menuPageData?.brand?.name}</h1>
                  <p>{menuPageData?.brand?.subTitle}</p>
                </div>
              </div>
              <div className="col-lg-9">
                <img
                  className="w-100 inner-banner-img"
                  src={ImageLink(menuPageData?.brand?.main_banner_image)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section> :
          <section className="inner-banner" style={{
            backgroundImage: `url(../images/4.jpg)`,
            height: '308px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }} />} */}

        <section className="pt-8">
          <div className="col-lg-12">
            {currentProducts?.length > 0 ?
              <>
                <div className="row">
                  {currentProducts?.length > 0 &&
                    <div className="col-lg-3 pb-4">
                      <div className="">
                        <div className="card border shadow h-100">
                          <div className="card-header bg-transparent py-0 border-bottom">
                            <h4 className="fs-26 mb-3 mt-3 filter-title">
                              {menuPageData?.brand?.name}<span className="mx-1">({brandProduct?.length})</span>
                            </h4>
                          </div>
                          <div className="">
                            <div className="accordion-item border-bottom">
                              <div
                                className="accordion-title"
                                onClick={() => toggleAccordion("accordian3")}
                              >
                                <div>Gender</div>
                                <div>{accordionState.accordian3 ? "-" : "+"}</div>
                              </div>
                              {accordionState.accordian3 && (
                                <div className="accordion-content">
                                  <ul className="dd-list list-unstyled mb-0">
                                    <div className="inline-block text-font">
                                      {filterMale.length > 0 && <li className="mb-1">
                                        <input
                                          className="mr-2 custom-color cursor-pointer"
                                          type="checkbox"
                                          value="Male"
                                          checked={gender.includes("Male")}
                                          onChange={handleGenderChange} />
                                        <span
                                          className="text-secondary hover-primary border-bottom border-white border-hover-primary d-inline-block lh-12 cursor-none cursor-pointer"
                                        >
                                          Male
                                        </span>
                                      </li>}
                                      {filterFemale.length > 0 && <li className="mb-1">
                                        <input
                                          className="mr-2 custom-color cursor-pointer"
                                          type="checkbox"
                                          value="Female"
                                          checked={gender.includes("Female")}
                                          onChange={handleGenderChange}
                                        />
                                        <span className="text-secondary hover-primary border-bottom border-white border-hover-primary d-inline-block lh-12 cursor-pointer">
                                          Female
                                        </span>
                                      </li>}
                                      {filterUnisex.length > 0 && <li className="mb-1">
                                        <input
                                          className="mr-2 custom-color cursor-pointer"
                                          type="checkbox"
                                          value="Unisex"
                                          checked={gender.includes("Unisex")}
                                          onChange={handleGenderChange}
                                        />
                                        <span
                                          className="text-secondary hover-primary border-bottom border-white border-hover-primary d-inline-block lh-12 cursor-pointer"
                                        >
                                          Unisex
                                        </span>
                                      </li>}
                                    </div>
                                  </ul>
                                </div>
                              )}
                            </div>
                            {uniqueColors.length > 0 && <div className="accordion-item border-bottom">
                              <div
                                className="accordion-title"
                                onClick={() => toggleAccordion("accordian4")}
                              >
                                <div>Color</div>
                                <div>{accordionState.accordian4 ? "-" : "+"}</div>
                              </div>
                              {accordionState.accordian4 && (
                                <div className="accordion-content">
                                  <ul
                                    className="dd-list list-unstyled mb-0"
                                  >
                                    {uniqueColors &&
                                      uniqueColors.map((color, index) => {
                                        return (
                                          <li
                                            className="mb-1 text-font"
                                            key={index}
                                          >
                                            <input
                                              className="mr-2 custom-color cursor-pointer"
                                              type="checkbox"
                                              value={color.id}
                                              checked={selectedColors.includes(color.id)}
                                              onChange={(e) => handleColorChange(e, color.id)}
                                            />
                                            <span className="text-secondary hover-primary border-bottom border-white border-hover-primary d-inline-block lh-12 cursor-pointer">
                                              {color.name}
                                            </span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              )}
                            </div>}

                            <div className="accordion-item border-bottom">
                              <div
                                className="accordion-title"
                                onClick={() => toggleAccordion("accordian5")}
                              >
                                <div>Metal</div>
                                <div>{accordionState.accordian5 ? "-" : "+"}</div>
                              </div>
                              {accordionState.accordian5 && (
                                <div className="accordion-content">
                                  <ul
                                    className="dd-list list-unstyled mb-0"
                                  >
                                    {uniqueMetals &&
                                      uniqueMetals?.map((item, index) => {
                                        return (
                                          <li
                                            className="mb-1 text-font"
                                            key={index}
                                          >
                                            <input
                                              className="mr-2 custom-color cursor-pointer"
                                              type="checkbox"
                                              value={item.id}
                                              checked={metal.includes(item.id)}
                                              onChange={(e) => handleMetalChange(e, item.id)}
                                            />
                                            <span className="text-secondary hover-primary border-bottom border-white border-hover-primary d-inline-block lh-12 cursor-pointer">
                                              {item.name}
                                            </span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              )}
                            </div>
                            <div className="accordion-item ">
                              <div
                                className="accordion-title"
                                onClick={() => toggleAccordion("accordian6")}
                              >
                                <div>Purity</div>
                                <div>{accordionState.accordian6 ? "-" : "+"}</div>
                              </div>
                              {accordionState.accordian6 && (
                                <div className="accordion-content">
                                  <ul
                                    className="dd-list list-unstyled mb-0"
                                  >
                                    {uniquePurity &&
                                      uniquePurity.map((item, index) => {
                                        return (
                                          <li
                                            className="mb-1 text-font"
                                            key={index}
                                          >
                                            <input
                                              className="mr-2 cursor-pointer custom-color"
                                              type="checkbox"
                                              value={item.id}
                                              checked={purity.includes(item.id)}
                                              onChange={(e) => handlePurityChange(e, item.id)}
                                            />
                                            <span className="text-secondary hover-primary border-bottom border-white border-hover-primary d-inline-block lh-12 cursor-pointer">
                                              {item.name}
                                            </span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  <div className="col-lg-9">
                    <div className="row mb-4 overflow-hidden" data-animate="fadeInUp">
                      {currentProducts?.map((brandProduct, index) => {
                        return (
                          brandProduct.isActive === true && (
                            <div
                              key={index}
                              className={`col-6 col-sm-6 col-lg-3 mb-8 ${animate ? "fadeInUp " : ""
                                }`}
                              style={{ animationDelay: `${index * 0.1}s` }}
                            >
                              <div className="card border-0 hover-change-content product cursor-pointer">
                                <div className="card-img-top position-relative overflow-hidden">
                                  <div
                                    style={{
                                      backgroundImage: `url(${ImageLink(
                                        brandProduct.mediumproduct_image[0]
                                      )})`,
                                      transition: "transform 1.5s ease",
                                      transform:
                                        hoveredIndex === index
                                          ? "scale(1.2)"
                                          : "scale(1)",
                                    }}
                                    className="card-img ratio bg-img-cover-center ratio-1-1"
                                    onClick={() => {
                                      navigator(`${pathname}/${brandProduct.slug}`, {
                                        state: brandProduct,
                                      });
                                    }}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                  ></div>

                                  <div className="position-absolute pos-fixed-bottom px-4 px-sm-6 pb-5 d-flex w-100 justify-content-center content-change-horizontal">
                                    <Link
                                      target="_blank"
                                      to={`https://wa.me/91${contact[0]?.whatsup_no}?text=${encodeURIComponent(brandProduct.name)}`}
                                      data-toggle="tooltip"
                                      title="Whatsapp"
                                      className="d-flex align-items-center justify-content-center text-primary bg-white hover-white bg-hover-primary w-45px h-45px rounded-circle mr-2 border"
                                    >
                                      <i className="fab fa-whatsapp" />
                                    </Link>
                                    <span onClick={() => {
                                      navigator(`${pathname}/${brandProduct.slug}`, {
                                        state: brandProduct,
                                      });
                                    }}>
                                      <Link
                                        data-toggle="tooltip"
                                        title="Enquire Now"
                                        className="d-flex align-items-center justify-content-center text-primary bg-white hover-white bg-hover-primary w-45px h-45px rounded-circle mr-2 border"
                                      >
                                        <i className="fal fa-envelope-open-text" />
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="card-body text-center item-title "
                                  onClick={() => {
                                    navigator(`${pathname}/${brandProduct.slug}`, {
                                      state: brandProduct,
                                    });
                                  }}
                                >
                                  <div className="">
                                    <p className="letter-spacing-05 d-block font-weight-500 mb-1  hover-name">
                                      {windowWidth > 1024
                                        ? brandProduct.name?.length > 26
                                          ? `${brandProduct.name.substring(0, 26)}...`
                                          : brandProduct.name
                                        : brandProduct.name?.length > 10
                                          ? `${brandProduct.name.substring(0, 10)}...`
                                          : brandProduct.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
                {brandProduct.length === 100 ? (
                  <nav className="pb-11 pb-lg-14 overflow-hidden">
                    <ul className="pagination justify-content-center align-items-center mb-0">
                      <li
                        className={`page-item fs-12 ${currentPage === 1 ? "disabled" : ""
                          }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          tabIndex="-1"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="far fa-angle-double-left"></i>
                        </Link>
                      </li>
                      {Array.from({ length: totalPages }, (_, index) => (
                        <li
                          className={`page-item ${currentPage === index + 1 ? "active" : ""
                            }`}
                          key={index}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}
                      <li
                        className={`page-item fs-12 ${currentPage === totalPages ? "disabled" : ""
                          }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="far fa-angle-double-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                ) : (
                  ""
                )}
              </> :
              <img src="../images/comingsoon.jpg" width="100%" alt="coming soon" className="mb-8" />
            }
          </div>
        </section>
      </main>
    </>
  );
};

export default BrandProduct;