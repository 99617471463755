import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getGoldRate } from "../services/getGoldRate";
import { ImageLink } from "../constants";

const Header = ({ menu, logo, socialData }) => {
    const location = useLocation();
    const [goldRate, setGoldRate] = useState([]);
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [showFilterContent, setShowFilterContent] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [ktType, setKtType] = useState(22);
    const [ktPrice, setKtPrice] = useState(null);
    // const KT18 = goldRate?.[0]?.carat18;
    const KT22 = goldRate?.[0]?.carat22;
    const [date, setDate] = useState(new Date());
    const [showText, setShowText] = useState(true);
    const firstPath = location?.pathname?.split("/")[1];
    const fetchData = async () => {
        const goldRatePromise = getGoldRate();
        const [goldRateResponse] = await Promise.all([goldRatePromise]);
        setGoldRate(goldRateResponse.document);
    };

    useMemo(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const intervalDate = setInterval(() => {
            setDate(new Date());
        }, 24 * 60 * 60 * 1000); // Update every 24 hours

        return () => clearInterval(intervalDate);
    }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setShowText(prevShowText => !prevShowText);
    //     }, 2000); // Toggle every 3 seconds

    //     // Switch between 18KT and 22KT every 5 seconds
    //     const typeSwitchInterval = setInterval(() => {
    //         setKtType(22)

    //         // setKtType(prevType => (prevType === 18 ? 22 : 18));
    //     }, 3000); // Switch every 5 seconds

    //     return () => {
    //         clearInterval(interval);
    //         clearInterval(typeSwitchInterval);
    //     };
    // }, []);

    useEffect(() => {
        setKtPrice(KT22);
        // setIsActive(isActive);
    }, [ktType, KT22]);
    // useMemo(() => {
    //     if (goldRate.length > 0) {
    //         const isActive = goldRate[0]?.isActive;
    //         const KT22 = goldRate[0]?.carat22;
    //         const KT18 = goldRate[0]?.carat18;
    //         setKtPrice(ktType === 18 ? KT18 : KT22);
    //         setIsActive(isActive);
    //     }
    // }, [goldRate, ktType]);

    // useMemo(() => {
    //     const interval = setInterval(() => {
    //         setKtType((prevType) => (prevType === 18 ? 22 : 18));
    //     }, 2000);

    //     return () => clearInterval(interval);
    // }, []);

    // useMemo(() => {
    //     if (ktType === 18 && goldRate.length > 0) {
    //         setKtPrice(goldRate[0]?.carat18);
    //     } else if (ktType === 22 && goldRate.length > 0) {
    //         setKtPrice(goldRate[0]?.carat22);
    //     }
    // }, [ktType, goldRate]);

    const toggleFilterContent = () => {
        setShowFilterContent(!showFilterContent);
    };

    // const showText = true;
    const menuOpen = false;

    return (
        <>
            <header className="main-header navbar-light header-sticky header-sticky-smart">
                <div className="sticky-area">
                    <div className="container-fluid">
                        <div className="d-none d-xl-block">
                            <nav className="navbar navbar-expand-xl px-0 py-2 py-xl-0 row no-gutters">
                                <div className="col-xl-2">
                                    <Link className="navbar-brand mr-0" to="/">
                                        {logo &&
                                            logo.map((data, i) => {
                                                return data.showInHeader === true ? (
                                                    <img
                                                        key={i}
                                                        src={ImageLink(data.logo_image)}
                                                        alt="Logo"
                                                    />
                                                ) : (
                                                    ""
                                                );
                                            })}
                                    </Link>
                                </div>
                                <div
                                    className={`${ktPrice ? "col-xl-8" : "col-xl-10"
                                        } d-flex justify-content-center position-static`}
                                >
                                    <ul className="navbar-nav hover-menu main-menu px-0 mx-xl-n4">
                                        {menu?.map((menuItem, id) => {
                                            return (
                                                <React.Fragment key={id}>
                                                    {menuItem.isActive === true &&
                                                        menuItem.showInHeader === true && (
                                                            <li
                                                                className={`nav-item dropdown-item-shop py-2 py-xl-5 px-0 px-xl-4 ${menuItem.subMenus?.length > 0 && "dropdown"
                                                                    }`}
                                                                onMouseEnter={() =>
                                                                    setDropdownOpen(menuItem.menuUrl)
                                                                }
                                                                onMouseLeave={() => setDropdownOpen(null)}
                                                            >
                                                                <Link
                                                                    to={`/${menuItem.menuUrl}`}
                                                                    className={`nav-link ${menuItem.subMenus?.length > 0 &&
                                                                        "dropdown-toggle"
                                                                        } ${location.pathname ===
                                                                            `/${menuItem.menuUrl}` ||
                                                                            firstPath === `${menuItem.menuUrl}`
                                                                            ? "active-link "
                                                                            : ""
                                                                        } p-0 wrap`}
                                                                >
                                                                    {menuItem.name}
                                                                    <span className="caret" />
                                                                </Link>
                                                                {menuItem?.subMenus?.length > 0 &&
                                                                    menuItem?.name === "Our Collection" && (
                                                                        <ul
                                                                            className={`justify-content-center dropdown-menu pt-3 pb-0 pb-xl-3 x-animated x-fadeInUp ${dropdownOpen === menuItem.menuUrl
                                                                                ? "show"
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            {menuItem.subMenus?.map(
                                                                                (brand, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        {brand?.brand?.isActive ===
                                                                                            true && (
                                                                                                <li
                                                                                                    key={id}
                                                                                                    className="dropdown-item dropdown dropright brand-logos-nav"
                                                                                                    onClick={() =>
                                                                                                        setDropdownOpen(null)
                                                                                                    }
                                                                                                >
                                                                                                    <Link
                                                                                                        to={`/${menuItem.menuUrl}/${brand?.menuUrl}`}
                                                                                                        className="dropdown-link "
                                                                                                    >
                                                                                                        <img
                                                                                                            src={ImageLink(
                                                                                                                brand?.brand?.logo_image
                                                                                                            )}
                                                                                                            alt="Brand Logo"
                                                                                                            className="brand-nav-img"
                                                                                                            width={150}
                                                                                                            height={100}
                                                                                                        />
                                                                                                    </Link>
                                                                                                    <p className="text-center my-1">
                                                                                                        {brand?.name}
                                                                                                    </p>
                                                                                                </li>
                                                                                            )}
                                                                                    </React.Fragment>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    )}
                                                                {menuItem?.subMenus?.length > 0 &&
                                                                    menuItem?.name === "Products" && (
                                                                        <div
                                                                            className={`dropdown-menu dropdown-menu-xl px-0 pb-10 pt-5 dropdown-menu-listing overflow-hidden x-animated x-fadeInUp ${dropdownOpen === menuItem.menuUrl
                                                                                ? "show"
                                                                                : ""
                                                                                }`}
                                                                            onClick={() => setDropdownOpen(null)}
                                                                        >
                                                                            <div className="container container-xxl">
                                                                                <div className="row no-gutters w-100">
                                                                                    {Array.from({
                                                                                        length: Math.ceil(
                                                                                            menuItem.subMenus.length / 4
                                                                                        ),
                                                                                    }).map((_, rowIndex) => (
                                                                                        <div
                                                                                            className="col-2"
                                                                                            key={rowIndex}
                                                                                        >
                                                                                            {menuItem.subMenus
                                                                                                .slice(
                                                                                                    rowIndex * 4,
                                                                                                    (rowIndex + 1) * 4
                                                                                                )
                                                                                                .map((product, index) => (
                                                                                                    <div
                                                                                                        className="dropdown-item"
                                                                                                        key={index}
                                                                                                    >
                                                                                                        <Link
                                                                                                            className="dropdown-link"
                                                                                                            to={`/${menuItem.menuUrl}/${product?.menuUrl}`}
                                                                                                            onMouseEnter={() =>
                                                                                                                setHoveredCategory(
                                                                                                                    product.category.slug
                                                                                                                )
                                                                                                            }
                                                                                                            onMouseLeave={() =>
                                                                                                                setHoveredCategory(null)
                                                                                                            }
                                                                                                        >
                                                                                                            {product.name}
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                ))}
                                                                                        </div>
                                                                                    ))}
                                                                                    <div className="col-6 h-100">
                                                                                        <div className="card border-0 mt-2">
                                                                                            {hoveredCategory ? (
                                                                                                <img
                                                                                                    src={ImageLink(
                                                                                                        menuItem?.subMenus?.find(
                                                                                                            (item) =>
                                                                                                                item?.menuUrl ===
                                                                                                                hoveredCategory
                                                                                                        )?.category?.menu_image
                                                                                                    )}
                                                                                                    alt="Menu"
                                                                                                    className="card-img "
                                                                                                />
                                                                                            ) : (
                                                                                                <img
                                                                                                    src={ImageLink(
                                                                                                        menuItem?.subMenus[0]
                                                                                                            ?.category?.menu_image
                                                                                                    )}
                                                                                                    alt="Menu"
                                                                                                    className="card-img"
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </li>
                                                        )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </ul>
                                </div>
                                {ktPrice && (
                                    <div className="col-xl-2 position-relative">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <ul className="navbar-nav flex-row justify-content-xl-end d-flex flex-wrap text-body py-0 navbar-right ml-4">
                                                <li className="nav-item">
                                                    <div
                                                        className={`gold-rate wrap ${showText ? "fade-in" : "fade-out"
                                                            }`}
                                                    >
                                                        <span>
                                                            {date && date.toLocaleDateString("en-GB")}{" "}
                                                            {ktType} KT {ktPrice}
                                                        </span>
                                                        {/* <span>
                                                            {date && date.toLocaleDateString("en-GB")}{" "}
                                                            {ktType} KT {ktPrice}
                                                        </span> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </nav>
                        </div>
                    </div>
                    <div className="d-block d-xl-none">
                        <div className="text-center mobile-price">
                            <div className="text-white">
                                <span className={showText ? "fade-in" : "fade-out"}>
                                    {date && date.toLocaleDateString("en-GB")} {ktType} KT{" "}
                                    {ktPrice}
                                </span>
                            </div>
                        </div>
                        <nav className="navbar navbar-expand-xl px-0 py-2 py-xl-0 w-100 align-items-center">
                            {" "}
                            <button
                                className="navbar-toggler border-0 px-0 canvas-toggle"
                                type="button"
                                data-canvas="true"
                                onClick={toggleFilterContent}
                            >
                                <span className="fs-24 toggle-icon" />
                            </button>
                            <Link to="/" className="navbar-brand d-inline-block mx-auto">
                                {logo &&
                                    logo.map((data, i) => {
                                        return data.showInHeader === true ? (
                                            <img
                                                key={i}
                                                src={ImageLink(data.logo_image)}
                                                alt="Logo"
                                            />
                                        ) : (
                                            ""
                                        );
                                    })}
                            </Link>
                        </nav>
                    </div>
                </div>
            </header>
            <div
                className={`sidenav canvas-sidebar bg-white ${showFilterContent ? "show" : ""
                    }`}
            >
                <div className="canvas-overlay" onClick={toggleFilterContent}></div>
                <div className="pt-5 pb-7 card border-0 h-100">
                    <div className="d-flex align-items-center card-header border-0 py-0 pl-8 pr-7 mb-9 bg-transparent">
                        <Link to="/" className="d-block w-52px">
                            {logo &&
                                logo.map((data, i) => {
                                    return data.showInHeader === true ? (
                                        <img
                                            key={i}
                                            src={ImageLink(data.mobile_logo_image)}
                                            alt="Furnitor"
                                            onClick={toggleFilterContent}
                                        />
                                    ) : (
                                        ""
                                    );
                                })}
                        </Link>
                        <span
                            className="canvas-close d-inline-block text-right fs-24 ml-auto lh-1 text-primary"
                            onClick={toggleFilterContent}
                        >
                            <i className="fal fa-times" />
                        </span>
                    </div>
                    <div
                        className={`overflow-y-auto pb-6 pl-8 pr-7 card-body pt-0 canvas-close d-inline-block ${menuOpen ? "open" : ""
                            }`}
                    >
                        <ul className="navbar-nav main-menu px-0">
                            {menu.map((menuItem, id) => {
                                return (
                                    menuItem.isActive === true &&
                                    menuItem.showInFooter === true && (
                                        <li key={id} className="nav-item dropdown py-1 px-0">
                                            <Link
                                                className="nav-link dropdown-toggle p-0"
                                                to={`/${menuItem.menuUrl}`}
                                                data-toggle={
                                                    menuItem.subMenus?.length > 0 ||
                                                        menuItem.category?.length > 0
                                                        ? "dropdown"
                                                        : null
                                                }
                                            >
                                                <span onClick={toggleFilterContent}>
                                                    {menuItem.name}
                                                </span>
                                                {(menuItem.subMenus?.length > 0 ||
                                                    menuItem.category?.length > 0) && (
                                                        <span className="caret" />
                                                    )}
                                            </Link>
                                            <div className="dropdown-menu p-0 dropdown-menu-listing x-animated x-fadeInUp">
                                                {menuItem?.subMenus?.length > 0 &&
                                                    menuItem?.subMenus?.map((brand, index) => {
                                                        return (
                                                            <div
                                                                className="dropdown-item"
                                                                key={index}
                                                                onClick={toggleFilterContent}
                                                            >
                                                                <Link
                                                                    className="dropdown-link"
                                                                    to={`/${menuItem.menuUrl}/${brand.menuUrl}`}
                                                                >
                                                                    {brand.name}
                                                                </Link>
                                                            </div>
                                                        );
                                                    })}
                                                {menuItem?.category?.length > 0 &&
                                                    menuItem?.category?.map((category, index) => {
                                                        return (
                                                            <div
                                                                className="dropdown-item"
                                                                key={index}
                                                                onClick={toggleFilterContent}
                                                            >
                                                                <Link
                                                                    className="dropdown-link"
                                                                    to={`/${menuItem.menuUrl}/${category.slug}`}
                                                                >
                                                                    {category.name}
                                                                </Link>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </li>
                                    )
                                );
                            })}
                            <li className="nav-item py-1 px-0">
                                <Link
                                    className="nav-link p-0"
                                    to="/book-appointment"
                                    onClick={toggleFilterContent}
                                >
                                    Book Appointment
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="card-footer bg-transparent border-0 mt-auto pl-8 pr-7 pb-0 pt-4">
                        <ul className="list-inline d-flex align-items-center mb-3">
                            {socialData.map((social, i) => {
                                return (
                                    social.isActive === true && (
                                        <li className="list-inline-item mr-4" key={i}>
                                            <Link
                                                target="_blank"
                                                to={social.url}
                                                className="fs-20 lh-1"
                                            >
                                                <i className={social.icon}></i>
                                            </Link>
                                        </li>
                                    )
                                );
                            })}
                        </ul>
                        <p className="mb-0 text-gray">
                            © {new Date().getFullYear()} K M Choksi jewellers.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
